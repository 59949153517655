export function formatPublishedAt(publishedAt: string): string {
  // Create a new Date object from the publishedAt string
  const date = new Date(publishedAt);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return "Invalid date";
  }

  // Use Intl.DateTimeFormat to format the date in a readable way
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
}

export const formatCategoryKey = (item: string[]) =>
  item?.map((name) => name).join("+");

export const parseCategoryKey = (key: string) => 
  key.split("+");
